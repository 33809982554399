import { Stack, Text } from "@mantine/core";
import {
  Dropzone,
  DropzoneProps,
  FileWithPath,
  MS_EXCEL_MIME_TYPE,
} from "@mantine/dropzone";
import { notifications } from "@mantine/notifications";
import { saveAs } from "file-saver";
import { useState } from "react";
import * as xlsx from "xlsx";

const MIME_TYPE_CSV = "text/csv";
const enum SHEET_TYPE {
  DKOInventorySnapshot = "3D/KO - Inventory Snapshot",
  KardexInventory = "KardexInventory",
  SalesTax3D = "3D - Sales Tax",
  SalesTaxKO = "KO - Sales Tax",
}
type DATA_SET = {
  data: any[];
  sheetType: SHEET_TYPE;
};

export default function App(props: Partial<DropzoneProps>) {
  const [isRunning, setIsRunning] = useState<boolean>(false);

  return (
    <Dropzone
      styles={{
        root: {
          width: "100%",
          height: "100%",
        },
        inner: {
          height: "100%",
        },
      }}
      loading={isRunning}
      onDrop={(files) => {
        setIsRunning(true);
        HandleFiles(files).finally(() => setIsRunning(false));
      }}
      onReject={() =>
        notifications.show({
          title: "Rejected files!",
          message: "",
          withBorder: true,
          color: "red",
        })
      }
      accept={[...MS_EXCEL_MIME_TYPE, MIME_TYPE_CSV]}
      {...props}
    >
      <Stack h="100%" align="center" justify="center">
        <Text size="xl">Drag files here or click to select files</Text>
        <Text size="sm" c="dimmed">
          Attach the xlsx, xls, csv extension files
        </Text>
      </Stack>
    </Dropzone>
  );
}

async function HandleFiles(files: FileWithPath[]) {
  const validDataSet = await Classify(files);
  const composedWorkBook = Compose(validDataSet);

  if (composedWorkBook.SheetNames.length) {
    notifications.show({
      title: "Compose successfully!",
      message: `[Detected WorkSheets]\n${validDataSet
        .map((d, i) => i + 1 + ". " + d.sheetType)
        .join("\n")}\n\n[Output]\n${composedWorkBook.SheetNames.map(
        (name, i) => i + 1 + ". " + name
      ).join("\n")}`,
      withBorder: true,
      color: "green",
    });

    // in the web worker, generate the XLSX file as a Uint8Array
    const u8 = xlsx.write(composedWorkBook, {
      type: "array",
      bookType: "xlsx",
    });
    saveAs(
      new Blob([u8], { type: "application/octet-stream" }),
      composedWorkBook.SheetNames[0] + ".xlsx"
    );
  } else
    notifications.show({
      title: "Compose failed!",
      message: validDataSet.length
        ? `[Detected WorkSheets]\n${validDataSet
            .map((d, i) => i + 1 + ". " + d.sheetType)
            .join("\n")}`
        : "",
      withBorder: true,
      color: "red",
    });
}

async function Classify(
  files: FileWithPath[]
): Promise<{ data: any; sheetType: SHEET_TYPE }[]> {
  const result: { data: any; sheetType: SHEET_TYPE }[] = [];

  for (const file of files) {
    const book = xlsx.read(await file.arrayBuffer());
    for (const sheetname of book.SheetNames) {
      const sheet = book.Sheets[sheetname];
      const data: any[] = xlsx.utils.sheet_to_json(sheet, { header: 1 });

      console.log(sheetname, data);

      // Classify
      if (data[2][0] === "3D/KO - Inventory Snapshot")
        result.push({ data, sheetType: SHEET_TYPE.DKOInventorySnapshot });
      else if (
        [
          "Item Number",
          "Display Name",
          "Vendor",
          "Subsidiary",
          "Lot",
          "Current quantity",
        ].every((s, i) => data[0][i] === s)
      )
        result.push({ data, sheetType: SHEET_TYPE.KardexInventory });
      else if (
        data[1][0] === "DDS Group : 3D BioCAD" &&
        data[2][0] === "DDS - Sales Tax"
      )
        result.push({ data, sheetType: SHEET_TYPE.SalesTax3D });
      else if (
        data[1][0] === "DDS Group : Kodent Dental Supply, Inc" &&
        data[2][0] === "DDS - Sales Tax"
      )
        result.push({ data, sheetType: SHEET_TYPE.SalesTaxKO });
    }
  }

  return result;
}

function CheckDataSet(dataSets: DATA_SET[], sheetTypes: SHEET_TYPE[]) {
  return (
    dataSets.length === sheetTypes.length &&
    dataSets.every((e) => sheetTypes.includes(e.sheetType))
  );
}
function Compose(dataSets: DATA_SET[]): xlsx.WorkBook {
  const book = xlsx.utils.book_new();

  if (CheckDataSet(dataSets, [SHEET_TYPE.DKOInventorySnapshot])) {
    const result: {
      Vendor: string;
      "Internal ID": Number;
      Name: string;
      Description: string;
      "Serial/Lot Number": string;
      "Bin Number": string;
      "On Hand": number;
    }[] = [];

    let vendor = "";
    dataSets
      .find((s) => s.sheetType === SHEET_TYPE.DKOInventorySnapshot)
      ?.data.forEach((d, i) => {
        if (i < 8) return;

        if (d[0]) vendor = d[0];
        if (d[1]) {
          d[0] = vendor;
          if (d[4] && (d[4] as string).includes(",")) {
            (d[4] as string).split(",").forEach((lotQty) =>
              result.push({
                Vendor: d[0],
                "Internal ID": d[1],
                Name: d[2],
                Description: d[3],
                "Serial/Lot Number": lotQty.substring(0, lotQty.indexOf("(")),
                "Bin Number": d[5],
                "On Hand": Number.parseInt(
                  lotQty.match(/\d+(?=\))/)?.[0] ?? "0"
                ),
              })
            );
          } else
            result.push({
              Vendor: d[0],
              "Internal ID": d[1],
              Name: d[2],
              Description: d[3],
              "Serial/Lot Number": d[4]
                ? d[4].substring(0, d[4].indexOf("("))
                : "",
              "Bin Number": d[5],
              "On Hand": d[6],
            });
        }
      });

    const sheet = xlsx.utils.json_to_sheet(result);
    xlsx.utils.book_append_sheet(book, sheet, "Split Qty Lot");
  } else if (
    CheckDataSet(dataSets, [
      SHEET_TYPE.DKOInventorySnapshot,
      SHEET_TYPE.KardexInventory,
    ])
  ) {
    const netsuite: {
      Vendor: string;
      "Internal ID": Number;
      Name: string;
      "Serial/Lot Number": string;
      "Bin Number": string;
      "On Hand": number;
    }[] = [];
    const kardex: {
      "Item Number": string;
      "Display Name": string;
      Vendor: string;
      Subsidiary: string;
      Lot: string;
      "Current quantity": number;
    }[] = [];
    const result: {
      Vendor: string;
      "NetSuite Internal ID": Number;
      Name: string;
      "Serial/Lot Number": string;
      "NetSuite Bin": string;
      "NetSuite Qty": number;
      "Kardex Qty": number;
      IsSame: boolean;
    }[] = [];

    // Get data from worksheet
    let vendor = "";
    dataSets
      .find((s) => s.sheetType === SHEET_TYPE.DKOInventorySnapshot)
      ?.data.slice(8)
      .forEach((d) => {
        if (d[0]) vendor = d[0];
        if (d[1]) {
          d[0] = vendor;
          // If have multiple lot
          if (d[4] && (d[4] as string).includes(",")) {
            (d[4] as string).split(",").forEach((lotQty) =>
              netsuite.push({
                Vendor: d[0],
                "Internal ID": d[1],
                Name: (d[2] ?? "").trim(),
                "Serial/Lot Number": lotQty.substring(0, lotQty.indexOf("(")),
                "Bin Number": d[5],
                "On Hand": Number.parseInt(
                  lotQty.match(/\d+(?=\))/)?.[0] ?? "0"
                ),
              })
            );
          } else
            netsuite.push({
              Vendor: d[0],
              "Internal ID": d[1],
              Name: (d[2] ?? "").trim(),
              "Serial/Lot Number": d[4]
                ? d[4].substring(0, d[4].indexOf("("))
                : "",
              "Bin Number": d[5],
              "On Hand": d[6],
            });
        }
      });
    dataSets
      .find((s) => s.sheetType === SHEET_TYPE.KardexInventory)
      ?.data.slice(1)
      .forEach((d) => {
        kardex.push({
          "Item Number": (d[0] ?? "").trim(),
          "Display Name": d[1],
          Vendor: d[2],
          Subsidiary: d[3],
          Lot: (d[4] ?? "").trim(),
          "Current quantity": d[5],
        });
      });

    // Sort imported data
    netsuite.sort((a, b) => {
      const c = a.Name.localeCompare(b.Name);
      return c !== 0
        ? c
        : a["Serial/Lot Number"].localeCompare(b["Serial/Lot Number"]);
    });
    kardex.sort((a, b) => {
      const c = a["Item Number"].localeCompare(b["Item Number"]);
      return c !== 0 ? c : a.Lot.localeCompare(b.Lot);
    });

    // Compose and get result
    let ni = 1;
    let ki = 1;
    const pushNetSuite = () => {
      result.push({
        Vendor: netsuite[ni].Vendor,
        "NetSuite Internal ID": netsuite[ni]["Internal ID"],
        Name: netsuite[ni].Name,
        "Serial/Lot Number": netsuite[ni]["Serial/Lot Number"],
        "NetSuite Bin": netsuite[ni]["Bin Number"],
        "NetSuite Qty": netsuite[ni]["On Hand"] ?? 0,
        "Kardex Qty": 0,
        IsSame: (netsuite[ni]["On Hand"] ?? 0) === 0,
      });
    };
    const pushKardex = () => {
      result.push({
        Vendor: kardex[ki].Vendor,
        "NetSuite Internal ID": 0,
        Name: kardex[ki]["Item Number"],
        "Serial/Lot Number": kardex[ki].Lot,
        "NetSuite Bin": "",
        "NetSuite Qty": 0,
        "Kardex Qty": kardex[ki]["Current quantity"] ?? 0,
        IsSame: (kardex[ki]["Current quantity"] ?? 0) === 0,
      });
    };
    const pushBoth = () => {
      result.push({
        Vendor: netsuite[ni].Vendor,
        "NetSuite Internal ID": netsuite[ni]["Internal ID"],
        Name: netsuite[ni].Name,
        "Serial/Lot Number": netsuite[ni]["Serial/Lot Number"],
        "NetSuite Bin": netsuite[ni]["Bin Number"],
        "NetSuite Qty": netsuite[ni]["On Hand"] ?? 0,
        "Kardex Qty": kardex[ki]["Current quantity"] ?? 0,
        IsSame:
          (netsuite[ni]["On Hand"] ?? 0) ===
          (kardex[ki]["Current quantity"] ?? 0),
      });
    };
    for (; ni < netsuite.length && ki < kardex.length; ) {
      if (netsuite[ni].Name === kardex[ki]["Item Number"]) {
        if (netsuite[ni]["Serial/Lot Number"] === kardex[ki].Lot) {
          pushBoth();
          ni++;
          ki++;
        } else if (
          netsuite[ni]["Serial/Lot Number"].localeCompare(kardex[ki].Lot) < 0
        ) {
          pushNetSuite();
          ni++;
        } else {
          pushKardex();
          ki++;
        }
      } else if (
        netsuite[ni].Name.localeCompare(kardex[ki]["Item Number"]) < 0
      ) {
        pushNetSuite();
        ni++;
      } else {
        pushKardex();
        ki++;
      }
    }

    // For outer join
    for (; ni < netsuite.length; ni++) pushNetSuite();
    for (; ki < kardex.length; ki++) pushKardex();

    // Final sort by vendor, name, lot
    result.sort((a, b) => {
      const c1 = (a.Vendor ?? "").localeCompare(b.Vendor);
      const c2 = (a.Name ?? "").localeCompare(b.Name);
      const c3 = (a["Serial/Lot Number"] ?? "").localeCompare(
        b["Serial/Lot Number"]
      );

      return c1 !== 0 ? c1 : c2 !== 0 ? c2 : c3;
    });
    const sheet = xlsx.utils.json_to_sheet(result);
    xlsx.utils.book_append_sheet(book, sheet, "Compare NetSuite and Kardex");
  } else if (CheckDataSet(dataSets, [SHEET_TYPE.SalesTax3D])) {
    const data: {
      Date: number;
      Type: string;
      Document: string;
      Customer: string;
      City: string;
      State: string;
      County: string;
      "County #": string;
      "City #": string;
      "Location #": string;
      Class: string;
      Item: string;
      "Non-Taxable": number;
      "Taxable Amount": number;
      "Tax Collected": number;
      "Tax Rate": number;
    }[] = dataSets[0]?.data.slice(7).map((d) => ({
      Date: d[0],
      Type: d[1],
      Document: d[2],
      Customer: d[3],
      City: d[4],
      State: d[5],
      County: d[6],
      "County #": d[7],
      "City #": d[8],
      "Location #": d[9],
      Class: d[10],
      Item: d[11],
      "Non-Taxable": d[12],
      "Taxable Amount": d[13],
      "Tax Collected": d[14],
      "Tax Rate": d[15],
    }));

    for (let i = 0; i < data.length; i++) {
      if (
        data[i]["Non-Taxable"] === 0 &&
        data[i]["Taxable Amount"] === 0 &&
        data[i]["Tax Collected"] === 0
      )
        data.splice(i--, 1);
      else {
      }
    }

    const sheet = xlsx.utils.json_to_sheet(data);
    xlsx.utils.book_append_sheet(book, sheet, "Master");
  }

  return book;
}
